import React from 'react';
import * as styles from './expose.module.scss';
import * as detailStyles from './expose-detail.module.scss';

import RcImmoLogo from "../../images/rc_immo.png"
import RcLogo from "../../images/logo.png"
import { getStrapiUrl } from "../../helper/uri"
import PlaceSVG from "../../icons/envato/place.svg"
import BlueprintSVG from "../../icons/envato/blueprint.svg"
import HouseSVG from "../../icons/envato/house.svg"
import RcImmoPrice from "../../helper-components/immo/rcImmoPrice"
import RoomSVG from "../../icons/envato/room.svg"
import AnnualSVG from "../../icons/envato/immoConstructionYear.svg"
import PictureSVG from "../../icons/envato/picture.svg"
import ExposeProperty from "./expose-property"
import * as shortStyles from "./expose-short.module.scss"
import nl2br from "nl2br"
import { getHeizung } from "../../helper/immo"

const ExposeDetail = ( { immoContact, immo, styleProperty, titleBild, bilder } ) => {

  if (!immo) {
    return <div>keine Immobilie übermittelt</div>
  }

  let flaeche = immo.flaechen.gesamtflaeche;

  if (!flaeche) {
    flaeche = immo.flaechen.grundstuecksflaeche;
  }

  if (!flaeche) {
    flaeche = immo.flaechen.nutzflaeche;
  }

  if (!flaeche) {
    flaeche = immo.flaechen.wohnflaeche;
  }

  const heizung = getHeizung(immo);

  return <div className={styles.expose}>

    <div className={"footer " + shortStyles.detailFooter}>
      <div className="d-flex justify-content-between align-items-center h-100">

        <div>
          <p>RC Immobilien</p>
          <p>A-4600 Wels, Durisolstraße 7</p>
        </div>

        <div>
          <p>office@reischauer.at</p>
          <p>www.reischauer.at</p>
        </div>
      </div>

    </div>

    <div>
      <div className={styles.rcHeader + " mb-3"}>
        <div className="d-flex align-items-center justify-content-between">

          <img src={RcImmoLogo} />
          <img src={RcLogo} className={styles.rcLogo}/>

        </div>
      </div>

      <div className={"my-2 text-center"}>
        <h4 className={styles.exposeTitle}>Exposé</h4>
      </div>

      {titleBild &&
      <img className={detailStyles.headerImage + " img-fluid mx-auto"} src={titleBild.daten.url}/>
      }

      <div className="d-flex w-100 justify-content-between mt-5 mb-2">
        <p className="opacity-75 text-left">Objekt Nr. {immo.objektnr_extern}</p>
      </div>

      <h1 className="mt-2 mb-0">{immo.title}</h1>

      <p className="mt-2 opacity-75">Detail-Exposé</p>

    </div>

    <div className={"page pageStart"}>
      <div className="row no-gutters">

        <div className="d-flex align-items-center">
          <h2>Objektbeschreibung</h2>
        </div>


        {/*<div className="col-12 mb-3 mt-2">*/}
        {/*  {styleProperty === 'normal' &&*/}
        {/*  <table className={"table my-1 " + styles.propertyTable}>*/}
        {/*    <tbody>*/}
        {/*    <tr className="pt-3">*/}

        {/*      <td>*/}

        {/*        <div className={styles.immoProperty}>*/}
        {/*          <div className={styles.immoPropertyLabel + " d-flex flex-row align-items-center"}>*/}
        {/*            <BlueprintSVG className={"mr-2"}/>*/}
        {/*            Fläche*/}
        {/*          </div>*/}

        {/*          <div className={styles.immoPropertyValue}>*/}
        {/*            {(flaeche + "").replace('.', ',')} m²*/}
        {/*          </div>*/}
        {/*        </div>*/}

        {/*      </td>*/}

        {/*      <td>*/}
        {/*        <div className="immoProperty">*/}

        {/*          <div className={styles.immoPropertyLabel + " d-flex flex-row align-items-center"}>*/}
        {/*            <HouseSVG className={"mr-2 "}/>*/}
        {/*            Miete*/}
        {/*          </div>*/}

        {/*          <div className={styles.immoPropertyValue}>*/}
        {/*            <RcImmoPrice subInfoPlacement={"none"} immo={immo}/>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </td>*/}

        {/*      <td>*/}
        {/*        <div className="immoProperty">*/}

        {/*          <div className={styles.immoPropertyLabel + " d-flex flex-row align-items-center"}>*/}
        {/*            <RoomSVG className={"mr-2"}/>*/}
        {/*            Zimmer*/}
        {/*          </div>*/}

        {/*          <div className={styles.immoPropertyValue}>*/}
        {/*            {immo.flaechen.anzahl_zimmer ? Number.parseInt(immo.flaechen.anzahl_zimmer) : 1}*/}
        {/*            <span className="sub d-inline-block">&nbsp;Zimmer</span>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </td>*/}

        {/*      <td>*/}
        {/*        <div className="immoProperty">*/}

        {/*          <div className={styles.immoPropertyLabel + " d-flex flex-row align-items-center"}>*/}
        {/*            <AnnualSVG className={"mr-2"}/>*/}
        {/*            Baujahr*/}
        {/*          </div>*/}

        {/*          <div className={styles.immoPropertyValue}>*/}
        {/*            {immo.zustand_angaben.baujahr}*/}
        {/*          </div>*/}

        {/*        </div>*/}
        {/*      </td>*/}

        {/*    </tr>*/}
        {/*    </tbody>*/}
        {/*  </table>*/}
        {/*  }*/}

        {/*  {styleProperty === 'alternative' &&*/}
        {/*  <h4 className="mt-2 mb-0">*/}
        {/*    {(immo.flaechen.gesamtflaeche + "").replace('.', ',')} m²,*/}
        {/*    &nbsp; <RcImmoPrice immo={immo} showFull={true} subInfoPlacement={'none'} />*/}
        {/*  </h4>}*/}
        {/*</div>*/}

        <div className={"col-12 " + styles.info} dangerouslySetInnerHTML={
          {
            __html: nl2br(immo.freitexte.objektbeschreibung)
          }
        }/>


        <div className="col-12">
          <div className="mt-4 d-flex-align-items-center">
            <h2>Eigenschaften</h2>
          </div>
        </div>


        <div className="col-12">
          <div className="row no-gutters">
            <div className={styles.twoColumns + " w-100 mt-2"}>
              <ExposeProperty valueType={"currency"} value={immo.preise?.nettokaltmiete} label={"Netto-Miete"} />
              <ExposeProperty valueType={"currency"} value={immo.preise?.nettomiete} label={"Netto-Miete"} />
              <ExposeProperty valueType={"currency"} value={immo.preise?.nebenkosten} label={"Nebenkosten"} />
              <ExposeProperty valueType={"currency"} value={immo.preise?.gesamtmietenetto} label={"Gesamt Miete Netto"} />
              <ExposeProperty valueType={"currency"} value={immo.preise?.gesamtmietebrutto} label={"Gesamt Miete inkl. Ust"} />
              <ExposeProperty valueType={"currency"} value={immo.preise?.gesamtbelastungbrutto} label={"Summe Belastung inkl. Ust"} />
              <ExposeProperty valueType={"currency"} value={immo.preise?.summemietenetto} label={"Summe Miete Netto"} />
              <ExposeProperty valueType={"currency"} value={immo.preise?.mietpreis_pro_qm} label={"Mietpreis pro m²"} />
              <ExposeProperty valueType={"currency"} value={immo.preise?.kaufpreis_pro_qm} label={"Kaufpreis pro m²"} />
              <ExposeProperty valueType={"currency"} value={immo.preise?.betriebskostennetto} label={"Betriebskosten Netto"} />
              <ExposeProperty valueType={"currency"} value={immo.preise?.monatlichekostenbrutto} label={"monatliche Kosten inkl. Ust"} />
              <ExposeProperty valueType={"currency"} value={immo.preise?.provisionbrutto} label={"Provision inkl. Ust"} />


              {immo.fleachen?.nutzflaeche !== immo.flachen?.gesamtflache &&
              <ExposeProperty valueType={"area"} value={immo.flaechen?.nutzflaeche} label={"Nutzfläche"}/>
              }


              <ExposeProperty valueType={"area"} value={immo.flaechen?.gesamtflaeche} label={"Gesamtfläche"} />
              <ExposeProperty valueType={"area"} value={immo.flaechen?.grundstuecksflaeche} label={"Grundstücksfläche"} />
              <ExposeProperty valueType={"int"} value={immo.flaechen?.anzahl_stellplaetze} label={"Anzahl Stellplätze"} />
              <ExposeProperty valueType={"int"} value={immo.zustand_angaben?.baujahr} label={"Baujahr"} />
            </div>

          </div>
        </div>



        {immo?.zustand_angaben?.energiepass &&
        <>
          <div className={detailStyles.energie + " col-12"}>
            <h2 className={"mt-4"}>Energieausweis</h2>
            <table className={"table mt-2 " + detailStyles.energieTable}>

              {immo.zustand_angaben.energiepass.epart &&
              <tr>
                <td>
                  <span className={"opacity-75"}>Energieausweistyp</span>
                </td>

                <td>
                  {immo.zustand_angaben.energiepass.epart.slice(0, 1).toUpperCase()}
                  {immo.zustand_angaben.energiepass.epart.slice(1).toLowerCase()}
                </td>
              </tr>
              }


              {immo.zustand_angaben.energiepass.energieverbrauchkennwert &&
              <tr>
                <td>
                  <span className={"opacity-75"}>Energieverbrauchkennwert</span>
                </td>

                <td>{immo.zustand_angaben.energiepass.energieverbrauchkennwert}</td>
              </tr>
              }


              {immo.zustand_angaben.energiepass.hwbwert &&
              <tr>
                <td>
                  <span className={"opacity-75"}>HWB-Wert: </span>
                </td>

                <td>
                  {immo.zustand_angaben.energiepass.hwbwert} kWh/(m²a)
                </td>
              </tr>
              }


              {immo.zustand_angaben.energiepass.hwbklasse &&
              <tr>
                <td>
                  <span className={"opacity-75"}>HWB-Klasse: </span>
                </td>

                <td>
                  {immo.zustand_angaben.energiepass.hwbklasse}
                </td>
              </tr>
              }

              { heizung &&
              <tr>
                <td>
                  <span className={"opacity-75"}>Heizung: </span>
                </td>

                <td>
                  {heizung}
                </td>
              </tr>

              }

            </table>
          </div>
        </>}

      </div>
    </div>

    <div className={"page "}>

      {bilder.length > 0 &&
      <div className="d-flex align-items-center">
        <PictureSVG className={"mr-2"}/>
        <h2 className="my-0">
          Impressionen
        </h2>
      </div>
      }

      <div className="row no-gutters">
        {
          bilder
            .filter(
              i => i.daten
            )
            .map(
              (i, idx) => {
                let addition = null;

                if (idx === 7) {
                  addition = <div className={styles.pageBreak} />
                }

                return <>

                  <div key={i.id} className={"col-6 "}>
                    <div className={styles.imprintImageWrapper}>
                      <img src={i.daten.url} className="img-fluid mx-auto"/>
                    </div>
                  </div>

                  {addition}

                </>
              }
            )
        }
      </div>




      <div className={styles.contactWrapper}>
        <div className="d-flex align-items-center">
          <h2 className="mt-5 mb-2">
            Ihr Ansprechpartner
          </h2>
        </div>

        <div className="row no-gutters">
          <div className="col-12">

            <div className="d-flex justify-content-between">
              <img className={shortStyles.qr} src={immo.qrCodeDataUrl} />

              <div className={"d-flex align-items-center"}>

                <div className={"mr-3"}>
                  <p>{immoContact?.fullname}</p>
                  <p>{immoContact?.phone}</p>
                  <p>{immoContact?.mail}</p>

                  <p className="mt-3">
                    https://www.reischauer.at
                  </p>

                </div>

                {immoContact?.avatar?.url &&
                <img className={shortStyles.contactImage} src={immoContact?.avatar?.url}/>
                }

              </div>
            </div>

          </div>
        </div>
      </div>



    </div>

    {/*<div className={"page"}>*/}

    {/*  <div className="d-flex align-items-center">*/}
    {/*    <PlaceSVG className={"mr-2"} />*/}
    {/*    <h2 className="my-0">*/}
    {/*      Lage*/}
    {/*    </h2>*/}
    {/*  </div>*/}

    {/*  <p className={"mb-3"}>*/}
    {/*    {immo.geo?.strasse} {immo.geo?.hausnummer}<br />{immo?.geo?.plz} {immo?.geo?.ort}*/}
    {/*  </p>*/}

    {/*  /!*<img className={"w-100"} src={`https://maps.geoapify.com/v1/staticmap?style=osm-bright-smooth&width=2000&height=1250&center=lonlat:${immo.longitude},${immo.latitude}&zoom=15.0202&marker=lonlat:${immo.longitude},${immo.latitude};color:%23007eff;size:x-large&apiKey=fdf1e9509e09423fa359e774c783b7c0`} />*!/*/}
    {/*</div>*/}


  </div>
};

export default ExposeDetail;

